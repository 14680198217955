<template>
  <div class="chat-message-container">
    <div v-if="chat.type == 'user'" class="chat-message-user">{{ chat.text }}</div>
    <div v-if="chat.type == 'user'" class="user-icon">
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5A17EE"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
    </div>
    <div v-if="chat.type == 'bot'" class="bot-icon">
      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#5A17EE"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><path d="M20,9V7c0-1.1-0.9-2-2-2h-3c0-1.66-1.34-3-3-3S9,3.34,9,5H6C4.9,5,4,5.9,4,7v2c-1.66,0-3,1.34-3,3s1.34,3,3,3v4 c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4c1.66,0,3-1.34,3-3S21.66,9,20,9z M18,19L6,19V7h12V19z M9,13c-0.83,0-1.5-0.67-1.5-1.5 S8.17,10,9,10s1.5,0.67,1.5,1.5S9.83,13,9,13z M16.5,11.5c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S14.17,10,15,10 S16.5,10.67,16.5,11.5z M8,15h8v2H8V15z"/></g></g></svg>
    </div>
    <div v-if="chat.type == 'bot'" class="chat-message" v-html="chat.text"></div>
    <template v-if="chat.type == 'qr'">
      <div class="buttons">
        <button v-for="(button, index) in chat.buttons" :key="index"
                class="button"
                @click="submitQR(index)">
          {{button.title}}
        </button>
      </div>
    </template>

    <div v-if="chat.type == 'waiting'" class="chat-message-typing">
      <div id="typing">
        <span class="typing-dot"></span>
        <span class="typing-dot"></span>
        <span class="typing-dot"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatMessage',
  props: {
    chat: Object
  },
  inject: ['botService'],
  mounted () {
    this.$el.scrollIntoView()
  },
  methods: {
    submitQR (index) {
      const userMessage = this.chat.buttons[index]
      this.$emit('chatUser', userMessage)
      this.botService.send(userMessage.payload)
      document.getElementById('txtChatBox').focus()
    }
  }
}
</script>

<style scoped>
.chat-message-container {
  margin-bottom: 8px;
  display: flex;
}
.chat-message,
.chat-message-typing {
  background: rgba(90, 23, 238, 1);
  border-radius: 0 16px 16px 16px;
  padding: 8px 16px;
  max-width: 300px;
  color: #fff;
}
.chat-message-typing {
  width: 24px;
}
.chat-message-user {
  background: rgba(90, 23, 238, 0.1);
  border-radius: 16px 0px 16px 16px;
  padding: 8px 16px;
  max-width: 300px;
  margin-left: auto;
  overflow-wrap: break-word;
  display: inline-block;
  flex-direction: column;
}
.buttons {
  display: flex;
  justify-content: center;
  flex: 1;
}
.submit {
  width: 100px;
  align-self: flex-end;
}
.full-width {
  width: 100%;
}
#typing {
  text-align: center;
}
.typing-dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 3px;
  background: #fff;
  animation: typing 1.6s linear infinite;
}
.typing-dot:nth-child(2) {
  animation-delay: -1.4s;
}
.typing-dot:nth-child(3) {
  animation-delay: -1.2s;
}
@keyframes typing {
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-5px);
  }
}
.user-icon,
.bot-icon {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  box-shadow: 0px 0px 0.2rem 0px rgb(90 23 238);
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-icon {
  margin-left: 8px;
}
.bot-icon {
  margin-right: 8px;
}
</style>
