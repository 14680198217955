import { io } from 'socket.io-client'

export class BotService {
  constructor (url) {
    this.url = url
    this.socket = null
    this.onBotWait = null
    this.handoffPayload = null
  }

  start (onBotChat, onBotWait) {
    this.socket = io(this.url)
    this.onBotWait = onBotWait
    const self = this

    this.socket.on('connect', () => {
      console.log(`socket ${this.socket.id} connected to ${this.url}`)
      if (self.handoffPayload) {
        self.send(self.handoffPayload)
        self.handoffPayload = null
      }
    })

    this.socket.on('disconnect', () => {})

    this.socket.on('bot_uttered', (data) => {
      if (data.handoff_host) {
        self.url = data.handoff_host
        self.handoffPayload = `/handoff{ "from_host": "${data.handoff_host}" }`
        self.socket.close()
        self.start(onBotChat, onBotWait)
      } else {
        onBotChat(data)
      }
    })
  }

  send (message) {
    if (!this.socket.connected) {
      return
    }

    this.onBotWait()

    this.socket.emit('user_uttered', {
      message: message,
      session_id: this.socket.id
    })
  }
}
