<template>
  <div class="header">
    <div class="logo">
      <h1>BANK OF RASA</h1>
    </div>
    <span class="spacer"></span>
    <div class="user">
      <span class="material-icons material-icons-outlined">account_circle</span>
    </div>
  </div>
  <div class="mega-menu">
    <a>Checking</a>
    <a>Savings</a>
    <a>Credit Cards</a>
    <a>Home Loans</a>
    <a>Auto Loans</a>
    <a>Investing</a>
  </div>
  <div class="empty">
    <span class="spacer"></span>
  </div>
  <div class="spending">
    <div class="spending-text">
      <h3>Your spending, automatically tracked</h3>
      <div>
        <p>Feel more confident in your financial decisions with tools designed to help you budget and save. Category-specific interactive charts and longer-term spending trends help you identify where you are frequently over or under budget.</p>
        <p>You can also talk to our digital assistant to gain insights into your spending habits.</p>
        <button class="accent-button">
          Check it out
        </button>
      </div>
    </div>
    <div class="spending-image">
      <img src="/hero.png">
    </div>
  </div>
  <div class="card">
    <div>
      <div class="card-info">
        <h4>Hello, User</h4>
        <h5>Preferred Rewards Gold Member</h5>
      </div>
      <div class="card-info">
        <h6>Last Statement Balance</h6>
        <p>$2,380.50</p>
      </div>
      <div class="card-info">
        <h6>Minimum Due</h6>
        <p>$45.00</p>
      </div>
    </div>
    <div>
      <div class="card-icon">
        <h4>XXXX XXX X001</h4>
        <hr>
        <h6>Travel Blue&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;XX/XX</h6>
      </div>
      <div class="card-info">
        <h6>My Pre-selected Offer</h6>
        <h4>50,000 Bonus Points</h4>
      </div>
    </div>
    <div>
      <div class="card-info-box">
        <span class="material-icons material-icons-outlined">shopping_bag</span><span>My Rewards</span>
      </div>
      <div class="card-info-box">
        <span class="material-icons material-icons-outlined">credit_score</span><span>My Credit Score</span>
      </div>
      <div class="card-info-box">
        <span class="material-icons material-icons-outlined">payments</span><span>Transfer Money</span>
      </div>
      <div class="card-info-box">
        <span class="material-icons material-icons-outlined">account_balance_wallet</span><span>Open New Account</span>
      </div>
    </div>
    <div>
      <div class="card-info-box">
        <span class="material-icons material-icons-outlined">monetization_on</span><span>Bank of Rasa Life Plan</span>
      </div>
      <div class="card-info-box">
        <span class="material-icons material-icons-outlined">location_on</span><span>Find a Branch</span>
      </div>
      <div class="card-info-box">
        <span class="material-icons">local_atm</span><span>Find an ATM</span>
      </div>
      <div class="card-info-box">
        <span class="material-icons material-icons-outlined">today</span><span>Schedule an Appointment</span>
      </div>
    </div>
  </div>
  <div class="disclaimer">
    <h5>Banking Service Agreement</h5>
    <p>Investing in securities involves risks, and there is always the potential of losing money when you invest in securities. You should review any planned financial transactions that may have tax or legal implications with your personal tax or legal advisor.</p>
    <p>Bank of Rasa is a fictitious bank, not a member FDIC. Trust and fiduciary services are not provided.</p>
    <p>Banking, credit card, automobile loans, mortgage and home equity products are not really provided. Credit and collateral are subject to approval. Terms and conditions apply. This is not a commitment to lend. Programs, rates, terms and conditions are subject to change without notice.</p>
  </div>
  <div class="empty">
    <span class="spacer"></span>
  </div>
  <div class="mega-footer">
    <p>© 2021 Bank of Rasa, a fictitious bank</p>
  </div>

  <transition name="fade">
    <div v-show="showChatWindow" class="overlay"></div>
  </transition>
  <transition name="fade">
    <ChatWindow v-show="showChatWindow" @close-chat="closeChat"/>
  </transition>

  <div v-if="showChatButton" class="float" @click="openChat">
    <span class="material-icons float-chat">chat_bubble</span>
  </div>
</template>

<script>
import { BotService } from './services/BotService'
import { STT } from './services/STT'
import ChatWindow from './components/ChatWindow.vue'

export default {
  name: 'App',
  components: {
    ChatWindow
  },
  provide () {
    return {
      botService: new BotService(process.env.VUE_APP_RASA_SOCKET_URL),
      stt: new STT()
    }
  },
  data () {
    return {
      showChatButton: true,
      showChatWindow: false
    }
  },
  methods: {
    openChat () {
      this.showChatButton = false
      this.showChatWindow = true
    },
    closeChat () {
      this.showChatWindow = false
      this.showChatButton = true
    }
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 244, 250);
}
.header {
  padding: 16px 64px;
  display: flex;
  background-color: #fff;
}
.logo {
  width: 320px;
  height: 48px;
  display: flex;
  align-items: center;
  color: rgb(90, 23, 238);
}
.logo h1 {
  font-size: 30px;
}
.logo div {
  width: 80px;
  padding-top: 8px;
}
.user {
  display: flex;
  align-items: center;
  width: 48px;
}
.user span {
  font-size: 32px;
  color: rgb(90, 23, 238);
  cursor: pointer;
}
.mega-menu,
.mega-footer {
  display: flex;
  height: 48px;
  padding: 0px 64px;
  background-color: #fff;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}
.mega-menu a {
  cursor: pointer;
  color: rgb(90, 23, 238);
}
.mega-menu a:hover {
  text-decoration: underline;
}
.mega-footer {
  font-size: 12px;
  background-color: #fff;
  height: 32px;
}
.empty {
  display: flex;
  height: 32px;
  background-color: rgb(242, 244, 250);
}
.disclaimer {
  margin: auto;
  margin-top: 24px;
  width: 1170px;
}
.spending {
  height: 350px;
  margin: auto;
  display: flex;
}
.spending-text {
  width: 700px;
  display: flex;
  flex-direction: column;
}
.spending-image {
  width: 470px;
}
.spending-image img {
  width: 100%;
  height: auto;
}
.accent-button {
  width: 150px;
}
.card {
  margin: auto;
  width: 1170px;
  display: flex;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  min-height: 200px;
  align-items: center;
  justify-content: space-between;
}
.card-info {
  display: flex;
  margin: 16px;
  flex-direction: column;
}
.card-info h4,
.card-info h5,
.card-info h6,
.card-info p {
  margin: 2px;
}
.card-icon {
  margin: 16px;
  padding: 16px;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 8px;
}
.card-icon h4,
.card-icon h6 {
  margin: 4px;
}
.card-info-box {
  display: flex;
  align-items: center;
  margin: 16px;
  cursor: pointer;
}
.card-info-box span:nth-child(2) {
  margin-left: 4px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(90, 23, 238, 0.3);
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: rgb(90, 23, 238);
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  cursor: pointer;
}
.float-chat {
  margin-top:22px;
}
</style>
