<template>
  <div class="chat-container">
    <div class="chat-window">
      <div class="chat-header">
        <h3>Financial Assistant</h3>
        <span class="spacer"></span>
        <span class="material-icons material-icons-outlined close" @click="close">close</span>
      </div>
      <div class="chat-container-scroller">
        <ChatMessage v-for="chat in chats" :key="chat.id" :chat="chat" @chat-user="chatUser"/>
      </div>
      <ChatTextbox @chat-user="chatUser"/>
    </div>
  </div>
</template>

<script>
import ChatMessage from './ChatMessage.vue'
import ChatTextbox from './ChatTextbox.vue'

export default {
  name: 'ChatWindow',
  inject: ['botService'],
  data () {
    return {
      chats: []
    }
  },
  components: {
    ChatMessage,
    ChatTextbox
  },
  created () {
    this.botService.start(this.chatBot, this.botWait)
    console.log('created')
  },
  methods: {
    chatUser (userMessage) {
      const msg = userMessage.payload ? userMessage.title : userMessage
      const chat = {
        id: '' + this.chats.length,
        text: msg,
        type: 'user'
      }
      if (this.chats.length > 0 && this.chats[this.chats.length - 1].type === 'qr') {
        this.chats.pop()
      }
      this.chats.push(chat)
    },
    chatBot (botMessage) {
      if (this.chats.length > 0 && this.chats[this.chats.length - 1].type === 'waiting') {
        this.chats.pop()
      }
      const chat = {
        id: '' + this.chats.length,
        text: botMessage.text.replaceAll('\n', '<br>'),
        type: 'bot'
      }
      this.chats.push(chat)

      if (botMessage.quick_replies) {
        for (let i = 0; i < botMessage.quick_replies.length; i++) {
          botMessage.quick_replies[i].id = i
        }
        const quickReply = {
          id: '' + this.chats.length,
          buttons: botMessage.quick_replies,
          type: 'qr'
        }
        this.chats.push(quickReply)
      }
    },
    botWait () {
      const chat = {
        id: 'waiting-' + this.chats.length,
        type: 'waiting'
      }
      this.chats.push(chat)
    },
    close () {
      this.$emit('closeChat')
    }
  }
}
</script>

<style scoped>
h3 {
  color: rgb(90, 23, 238);
}
.close {
  cursor: pointer;
  border: 1px solid rgba(90, 23, 238, 1);
  color: rgba(90, 23, 238, 1);
  border-radius: 8px;
}
.chat-container {
  right: 0;
  position: fixed;
  display: flex;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.chat-container-scroller {
  flex: 1;
  overflow: scroll;
  padding: 8px 12px;
}
.chat-window {
  width: 480px;
  max-width: 480px;
  display: flex;
  flex-direction: column;
}
.chat-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px;
  box-shadow: 3px -1px 0.5rem 2px rgb(90 23 238);
  box-sizing: border-box;
}

/* Handsets small and large in portrait */
@media (max-width: 479px) {
  .chat-window {
      flex: 3;
  }
}
</style>
