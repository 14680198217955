<template>
  <div class="chat-textbox">
    <textarea id="txtChatBox"
              rows="3"
              :placeholder="placeholder"
              v-model="message"
              @keydown.enter.exact.prevent
              @keyup.enter.stop="submit">
    </textarea>
    <span class="material-icons material-icons-outlined mic"
          v-if="showMic"
          @mousedown="tryStartMic"
          @mouseup="stopMic">
          mic
    </span>
  </div>
</template>

<script>
export default {
  name: 'ChatTextbox',
  data () {
    return {
      message: '',
      showMic: false
    }
  },
  computed: {
    placeholder () {
      if (this.showMic) {
        return 'Start typing here or click and hold the mic to speak'
      }

      return 'Start typing here'
    }
  },
  methods: {
    tryStartMic () {
      this.stt.start()
    },
    stopMic () {
      this.stt.stop()
    },
    submit () {
      const msg = this.message
      this.message = ''

      if (msg && msg.trim().length > 0) {
        this.$emit('chatUser', msg)
        this.botService.send(msg)
      }
    },
    delayedSubmit () {
      window.setTimeout(() => {
        this.submit()
        document.getElementById('txtChatBox').focus()
      }, 210)
    }
  },
  inject: ['botService', 'stt'],
  async mounted () {
    this.showMic = await this.stt.init((msg) => { this.message = msg }, () => { this.delayedSubmit() })
  }
}
</script>

<style scoped>
.chat-textbox {
  margin: 12px;
  border: 1px solid rgba(90, 23, 238, 1);
  border-radius: 6px;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.chat-textbox textarea {
  width: calc(100% - 24px);
  height: 48px;
  border: none;
  outline: none;
  padding: 0 8px;
  resize: none;
}
.mic {
  margin-right: 4px;
  cursor: pointer;
  font-size: 36px;
  color: rgba(90, 23, 238, 1);
}
.mic:active {
  border: 1px solid rgba(90, 23, 238, 1);
  border-radius: 8px;
}
</style>
