/* eslint-disable */
/**
 * Recorder Module.
 * 
 * Based on the following:
 * @see {@link https://github.com/mattdiamond/Recorderjs}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder}
 * @see {@link https://github.com/aws-samples/aws-lex-web-ui/blob/master/lex-web-ui/src/lib/lex/recorder.js}
 * @see {@link https://webrtc.github.io/samples/src/content/getusermedia/volume/js/soundmeter.js}
 */

const STATE = {
  active: 'active',
  inactive: 'inactive'
};

/**
 * Converts the audio data into Pulse-code modulation (PCM) encoded buffer.
 * Transcribe expects PCM with additional metadata, encoded as binary.
 * 
 * @param {*} pInput Buffer of audio data.
 * @returns PCM encoded byte buffer.
 */
const pcmEncode = (pInput) => {  
  const rawData = new Float32Array(pInput.buffer);
  let offset = 0;
  let buffer = new ArrayBuffer(rawData.length * 2);
  let view = new DataView(buffer);

  for (let i = 0; i < rawData.length; i++, offset += 2) {
      let s = Math.max(-1, Math.min(1, rawData[i]));
      view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }

  return Buffer.from(buffer);
};

const downsample = (pBuffer, pInputSampleRate = 44100, pOutputSampleRate = 16000) => {
  if (pInputSampleRate === pOutputSampleRate) {
    return pBuffer;
  }

  const sampleRateRatio = pInputSampleRate / pOutputSampleRate;
  const newLength = Math.round(pBuffer.length / sampleRateRatio);
  let result = new Float32Array(newLength);
  let offsetResult = 0;
  let offsetBuffer = 0;
  
  while (offsetResult < result.length) {
    let nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
    let accum = 0;
    let count = 0;
    
    for (var i = offsetBuffer; i < nextOffsetBuffer && i < pBuffer.length; i++) {
      accum += pBuffer[i];
      count++;
    }

    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }

  return result;
};

export class Recorder {
  
  constructor() {
    // TODO: Load config from env file or something similar.
    this.config = {};
    this.config.mimeType = 'audio/wav';
    this.config.recordingTimeMax = 20;
    this.config.recordingTimeMin = 2;
    this.config.recordingTimeMinAutoIncrease = true;
    this.config.autoStopRecording = true;
    this.config.quietThreshold = 0.001;
    this.config.quietTimeMin = 0.4;
    this.config.volumeThreshold = -75;
    this.config.bufferLength = 4096;
    this.config.channelCount = 1;
    this.config.sampleRate = 44100;    
    this.config.echoCancellation = true;

    // Band pass config. See https://developer.mozilla.org/en-US/docs/Web/API/BiquadFilterNode
    this.config.useBandPass = false;
    this.config.bandPassFrequency = 4000;
    // Butterworth 0.707 [sqrt(1/2)]  | Chebyshev < 1.414
    this.config.bandPassQ = 0.707;

    // Automatic mute detection config.
    this.config.useAutoMuteDetect = true;
    this.config.muteThreshold = 1e-7;

    // Encoder config.
    this.config.encoderUseTrim = true;
    this.config.encoderQuietTrimThreshold = 0.0008;
    this.config.encoderQuietTrimSlackBack = 4000;

    this._init();
  }

  async* [Symbol.asyncIterator]() {
    while(this._state === STATE.active) {
      await new Promise((...a) => [this._resolveAudioDataQueue] = a);
      
      const downSampledBuffer = downsample(this._audioDataQueue.pop());
      const pcmEncodedChunk = pcmEncode(downSampledBuffer);

      // Format mimics AWS transcribe.
      yield { AudioEvent: { AudioChunk: pcmEncodedChunk } };
    }
  }

  /**
   * Initializes the recorder.
   *
   * @return {Promise} - Returns a promise that resolves when the recorder is ready.
   */
  async init() {
    try {
      // TODO: What if user does nothing?
      await this._initAudioStream();

      await this._createAudioContext();
      await this._createAudioProcessor();
      await this._createAndConnectAudioGraph();
      
      return true;

    } catch(e) {
      // Yes, log at debug level since it is pretty much about user declining access to mic,
      // or there's no mic or something along those lines.   
      console.debug(e);

      return false;
    }
        
  }

  start() {
    this._state = STATE.active;
    this._recordingStartTime = this._audioContext.currentTime;    
  }

  stop() {
    if (this._state !== STATE.active) return;

    if (this._recordingStartTime > this._quietStartTime) {
      this._isSilentRecording = true;
      this._silentRecordingConsecutiveCount += 1;    

    } else {
      this._isSilentRecording = false;
      this._silentRecordingConsecutiveCount = 0;    
    }

    this._recordingStartTime = 0;

    // Deactivate after a bit.
    window.setTimeout(() => {
      this._state = STATE.inactive;
    }, 200);
  }

  _init() {
    this._state = STATE.inactive;
    this._audioDataQueue = [];
    this._resolveAudioDataQueue = null;
    this._audioProcessor = null;
    this._audioContext = null;

    this._instant = 0.0;
    this._slow = 0.0;
    this._clip = 0.0;
    this._maxVolume = -Infinity;

    this._isMicQuiet = true;
    this._isMicMuted = false;

    this._isSilentRecording = true;
    this._silentRecordingConsecutiveCount = 0;
  }

  /**
   * Retrieves microphone's audio stream using "navigator.mediaDevices.getUserMedia".
   * 
   * @return {Promise} returns a promise that resolves when the audio input has been connected.
   */
   async _initAudioStream() {
    const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
    const audio = {
      deviceId: 'default'
    };

    const constraintsToConfigure = [ "channelCount", "echoCancellation" ];
    for (let constraintName in constraintsToConfigure) {
      if (supportedConstraints[constraintName]) {
        audio[constraintName] = this.config[constraintName];
      }
    }

    const constraints = {
      audio: audio,
      video: false
    };

    this._audioStream = await navigator.mediaDevices.getUserMedia(constraints);
  }

  async _createAudioContext() {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!window.AudioContext) {
      throw 'Web Audio API not supported.';
    }

    this._audioContext = new AudioContext();
    document.addEventListener('visibilitychange', () => {
      console.debug('visibility change triggered in recorder. hidden:', document.hidden);

      if (!this._audioContext || this._state !== STATE.active) return;

      if (document.hidden) {
        this._audioContext.suspend();

      } else {
        this._audioContext.resume().then(() => {
          console.debug('Playback resumed successfully from visibility change');
        });
      }
    });

    //await this._audioContext.audioWorklet.addModule('/pcm-worker.js');
  }

  async _createAudioProcessor() {
    // TODO: Move to worker thread.
    // Assumes a single channel.
    const processor = this._audioContext.createScriptProcessor(
      this.config.bufferLength,
      this.config.channelCount,
      this.config.channelCount,
    );

    processor.onaudioprocess = (evt) => {
      if (this._state === STATE.active) {
        let data; 

        if (this._recordingStartTime > 0) {
          data = evt.inputBuffer.getChannelData(0);

        } else {
          // Sent an empty buffer in the end.          
          data = new Buffer([]);
        }
        
        this._audioDataQueue.push(data);
        if (this._resolveAudioDataQueue) {
          this._resolveAudioDataQueue();
          this._resolveAudioDataQueue = null;
        }

        if (this._recordingStartTime > 0) {
          // Stop recording if we are over the maximum time.
          if ((this._audioContext.currentTime - this._recordingStartTime) > this.config.recordingTimeMax) {           
            this.stop();
            console.warn('Max recording time reached. Force stopped recording.');
          }
        }
      }

      // TODO: Hold on to the latest 'n' buffers to make sure we capture all the necessary audio?

      const input = evt.inputBuffer.getChannelData(0);
      let sum = 0.0;
      let clipCount = 0;
      for (let i = 0; i < input.length; ++i) {
        // square to calculate signal power
        sum += input[i] * input[i];
        if (Math.abs(input[i]) > 0.99) {
          clipCount += 1;
        }
      }
      this._instant = Math.sqrt(sum / input.length);
      this._slow = (0.95 * this._slow) + (0.05 * this._instant);
      this._clip = (input.length) ? clipCount / input.length : 0;

      // TODO: Notify external callers, so an action can be taken when the volume goes up/down.
      this._setIsMicMuted();
      this._setIsMicQuiet();

      this._analyser.getFloatFrequencyData(this._analyserData);
      this._maxVolume = Math.max(...this._analyserData);
    };

    this._audioProcessor = processor;
  }

  _createAndConnectAudioGraph() {    
    this._tracks = this._audioStream.getAudioTracks();

    // We are using a single channel.    
    this._tracks[0].onmute = this._setIsMicMuted;
    this._tracks[0].onunmute = this._setIsMicMuted;

    const source = this._audioContext.createMediaStreamSource(this._audioStream);
    const gainNode = this._audioContext.createGain();

    const analyser = this._audioContext.createAnalyser();
    analyser.fftSize = this.config.bufferLength;
    analyser.minDecibels = -90;
    analyser.maxDecibels = -30;

    if (this.useBandPass) {      
      const biquadFilter = this._audioContext.createBiquadFilter();
      biquadFilter.type = 'bandpass';
      biquadFilter.frequency.value = this.config.bandPassFrequency;
      biquadFilter.gain.Q = this.config.bandPassQ;

      source.connect(biquadFilter);
      biquadFilter.connect(gainNode);
      analyser.smoothingTimeConstant = 0.5;

    } else {
      source.connect(gainNode);
      analyser.smoothingTimeConstant = 0.9;
    }

    gainNode.connect(analyser);
    analyser.connect(this._audioProcessor);

    this._analyserData = new Float32Array(analyser.frequencyBinCount);
    this._analyser = analyser;

    this._audioProcessor.connect(this._audioContext.destination);    
  }

  _setIsMicMuted() {
    if (!this.config.useAutoMuteDetect) return;

    // TODO: consider max volume as well.
    if (this._instant >= this.muteThreshold) {
      if (this._isMicMuted) {
        this._isMicMuted = false;        
      }

      return;
    }

    if (!this._isMicMuted && (this._slow < this.muteThreshold)) {
      this._isMicMuted = true;      

      console.info(
        'mute - instant: %s - slow: %s - track muted: %s',
        this._instant, this._slow, this._isMicMuted,
      );

      if (this._state === STATE.active) {
        this.stop();
        console.info('Force stopped recording on since the mic has been muted (or so we think)');
      }
    }
  }

  _setIsMicQuiet() {
    const now = this._audioContext.currentTime;
    const isMicQuiet = this._maxVolume < this.config.volumeThreshold || this._slow < this.quietThreshold;

    // Record the time when the line goes quiet.
    if (!this._isMicQuiet && isMicQuiet) {
      this._quietStartTime = this._audioContext.currentTime;      
    }

    // Reset quiet timer when there's enough sound.
    if (this._isMicQuiet && !isMicQuiet) {
      this._quietStartTime = 0;
    }

    this._isMicQuiet = isMicQuiet;

    // If auto increase is enabled, exponentially increase the mimimun recording
    // time based on consecutive silent recordings.
    const recordingTimeMin = (this.config.recordingTimeMinAutoIncrease) ?
        (this.config.recordingTimeMin - 1) + (this.config.recordingTimeMax ** (1 - (1 / (this._silentRecordingConsecutiveCount + 1)))) :
        this.config.recordingTimeMin;

    // Detect voice pause and stop recording.
    if (this.config.autoStopRecording &&
      this._isMicQuiet && this._state === STATE.active &&
      // have I been recording longer than the minimum recording time?
      now - this._recordingStartTime > recordingTimeMin &&
      // has the slow sample value been below the quiet threshold longer than
      // the minimum allowed quiet time?
      now - this._quietStartTime > this.config.quietTimeMin
    ) {
      this.stop();
    }
  }
}
/* eslint-enable */
