/* eslint-disable */
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool} from '@aws-sdk/credential-provider-cognito-identity';
import { TranscribeStreamingClient, StartStreamTranscriptionCommand } from '@aws-sdk/client-transcribe-streaming';
import { Recorder } from './Recorder';

const AWS_REGION = process.env.VUE_APP_AWS_REGION;
const AWS_IDENTITY_POOL_ID = process.env.VUE_APP_AWS_IDENTITY_POOL_ID;

const AWS_CREDS = fromCognitoIdentityPool({
  client: new CognitoIdentityClient({ region: AWS_REGION }),
  identityPoolId: AWS_IDENTITY_POOL_ID
});

const transcribe = async (pRecorder, pOnTextLambda, pOnStoppedLambda) => {    
  const transcription = [ '' ];

  try {
    const command = new StartStreamTranscriptionCommand({            
      LanguageCode: 'en-US',
      MediaEncoding: 'pcm',
      // The sample rate of the input audio in Hertz. 
      // Amazon suggest's that we use 8000 Hz for low-quality audio and 16000 Hz for
      // high-quality audio. 
      MediaSampleRateHertz: 16000,
      AudioStream: pRecorder
    });

    const transcribeClient = new TranscribeStreamingClient({
      region: AWS_REGION,
      credentials: AWS_CREDS
    });
    const response = await transcribeClient.send(command);

    for await (const event of response.TranscriptResultStream) {
      if (event.TranscriptEvent) {
        const message = event.TranscriptEvent;                
        const results = event.TranscriptEvent.Transcript.Results;
        
        // TODO: Handle if there is more than one result.
        results.map((result) => {
          let newText = '';

          // TODO: Handle if there are more than one alternatives.
          (result.Alternatives || []).map((alternative) => {
            if (alternative.Items.length > 0) {
                
              for (let i = 0; i < alternative.Items.length - 1; i++) {
                newText += ' ' + alternative.Items[i].Content;
              }

              newText += alternative.Items[alternative.Items.length - 1].Content;
            }
              
            transcription[transcription.length - 1] = newText;

            // When a full text is acquired, save it in the "transcription" array. 
            if (!result.IsPartial) transcription.push('');
            
            pOnTextLambda(transcription.join(' '));
          });
        });
      }
    }

    pOnStoppedLambda();

  } catch (e) {
    // Possible errors:
    //     e.name === "InternalFailureException"
    //     e.name === "ConflictException"
    console.error(e); 
  }
};

export class STT {
  constructor() {        
    this._sampleRateHz = 16000;
    this._recorder = null;
    this._initialized = false;
  }

  async init(pOnTextLambda, pOnStoppedLambda) {
    this._onText = pOnTextLambda;
    this._onStopped = pOnStoppedLambda;
    this._recorder = new Recorder();
    this._initialized = await this._recorder.init();
    return this._initialized;
  }

  async start() {
    if (!this._recorder || !this._initialized) return;

    this._recorder.start();
    await transcribe(this._recorder, this._onText, this._onStopped);
  }

  async stop() {
    if (!this._recorder || !this._initialized) return

    await this._recorder.stop();     
  }
}
/* eslint-enable */
